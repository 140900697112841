<template>
    <gyro-access @moved="UpdateGyro" v-slot="gyro" ref="myGyro">
      <div v-if="!requested" class="tutorial p-5">
        <p class="is-size-6 has-text-black-ter has-text-weight-bold"> {{ $t('exp_external.leftRightInstruction') }} </p>
        <img src="/assets/external/leftright/tutorial.svg"/>
        <p> {{ $t('exp_external.leftRightSensors') }} </p>
        <b-button
          type="is-primary"
          size="is-medium"
          label="Activar sensores"
          expanded
          @click="RequestGyro()" />
      </div>

      <div v-else-if="showNoGyroTutorial" class="tutorial p-5">
        <p class="is-size-6 has-text-black-ter has-text-weight-bold">{{ $t('exp_external.leftRightNoSensors') }} </p>
        <img src="/assets/external/leftright/nogyro-tutorial.svg"/>
          <b-button
            type="is-primary"
            size="is-medium"
            label="Jugar"
            expanded
            @click="playWithButtons = true" />
      </div>

      <div v-else class="arrows">
        <div class="arrow left"
          :class="{active: arrowState == 'left'}"
          @mousedown="touchArrow('left')"
          @touchstart="touchArrow('left')"
          @mouseup="releaseArrow()"
          @touchend="releaseArrow()">
            <img src="/assets/external/leftright/left.svg"/>
        </div>
        <div class="arrow right"
          :class="{active: arrowState == 'right'}"
          @mousedown="touchArrow('right')"
          @touchstart="touchArrow('right')"
          @mouseup="releaseArrow()"
          @touchend="releaseArrow()">
            <img src="/assets/external/leftright/right.svg"/>
        </div>
      </div>
      <code v-if="debug" class="debugger is-size-7 p-4">
        {{ gyro }},
        {{ $t('exp_external.leftRightDirection') }}: {{ arrowState }}, {{ $t('exp_external.leftRightRotation') }}: {{ Math.round(rotation * 100) / 100 }}, 
      </code>
    </gyro-access>
</template>

<script>
import GyroAccess from "../GyroAccess";

export default {
  components: {
    GyroAccess
  },
  props: {
    debug: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      arrowState: 'neutral',
      direction: 'neutral',
      rotation: 0,
      threshold: 0.8,
      requested: false,
      playWithButtons: false
    };
  },
  computed: {
    showNoGyroTutorial(){
        return !this.$refs.myGyro.dataStarted && !this.playWithButtons
    }
  },
  methods: {
    RequestGyro(){
      this.requested = true
      this.$refs.myGyro.RequestGyro()
    },
    UpdateGyro(data) {
      var b = Math.abs(data.beta) / 90
      if(b > 1) b = 2 - b

      var g = data.gamma / 90
      if(Math.abs(data.beta) > 90) g = -g

      this.rotation = g / Math.max(0.25,b)
                                                                                                                                       
      // Posicion central
      if(Math.abs(this.rotation) < this.threshold) {
        this.arrowState = 'neutral'
        return
      }

      // // IZQUIERDA 
      if(this.rotation < -this.threshold) {

        this.arrowState = 'left'
  
        if(this.direction != 'left') {
          this.direction = 'left'
          this.emitDirection()
        }

        return
      }

      // // DERECHA
      if(this.rotation > this.threshold) {

        this.arrowState = 'right'

        if(this.direction != 'right') {
          this.direction = 'right'
          this.emitDirection()
        }

        return
      }

    },
    touchArrow(direction) {
      if(!this.$refs.myGyro.noGyro) return false
      this.arrowState = direction
      console.log('No hay giro, envia boton')
      this.direction = direction
      this.emitDirection()
    },
    releaseArrow(){
      if(!this.$refs.myGyro.noGyro) return false
      this.arrowState = 'neutral'
    },
    emitDirection() {
      this.$emit('change', {
        direction: this.direction
      });
    },
    callibrated() {
      // this.$socket.client.emit('gameMessage', {
      //   type: 'callibrated'
      // })
      // this.$sfxPlay('resetthecenter')
    },
  },
  mounted() {
    this.emitDirection();
  },

};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.gyroAccess .tutorial {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  height: 100%;
  overflow-y: auto;
  z-index: 0;
}

.gyroAccess {
  align-items: center;
  height: 100%;
}

.arrows {
    outline: solid;
    width: 100%;
    height: 100%;
    display: flex;

    & > div {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .arrow {

      transition: all 50ms;

      img {
        width: 50%;
        max-width: 200px;
      }

      &.right {
        background-color: rgba($black,.15);
      }

      &.left.active {
        background-color: $primary;
      }

      &.right.active {
        background-color: $primary;
      }
    }
}

.debugger {
  width: 100%;
  color: $info;
  border-top: solid 1px $info;
}
</style>